import { Routes, Route, useLocation } from 'react-router-dom';
import useDeviceSize from './hooks/use-device-size';
import NavigationHeader from './navigation-header/navigation-header';

import Admin from './routes/admin';
import Home from './routes/home';
import Contact from './routes/contact';
import Apartments from './routes/apartments';
import Reservation from './routes/reservation';
import BookingForm from './routes/booking-form';
import RentalInformation from './routes/rental-information';
import History from './routes/history';

import Footer from './footer/footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers';
import sv from 'date-fns/locale/sv';
import { svSE } from '@mui/x-date-pickers/locales';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SearchProvider } from './context/SearchContext';

import './App.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10,  // 10 minuter
      cacheTime: 1000 * 60 * 30,  // 30 minuter
    },
  },
});

const swedishLocale = svSE.components.MuiLocalizationProvider.defaultProps.localeText;

const theme = createTheme({
  palette: {
    primary: {
      main: '#ae8e61',
    },
    secondary: {
      main: '#3d0235',
    },
  },
});

function App() {
  const { isMobile, isTablet, isDesktop } = useDeviceSize();

  const location = useLocation();
  const locationName = location.pathname.split('/')[1];

  const classNames = ['App'];
  locationName && classNames.push(locationName);
  isMobile && classNames.push('mobile');
  isTablet && classNames.push('tablet');
  isDesktop && classNames.push('desktop');

  return (
    <div className={classNames.join(' ')}>
      <SearchProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv} localeText={swedishLocale}>
            <main>
              <NavigationHeader />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/history" element={<History />} />
                <Route path="/reservation" element={<Reservation />} />
                <Route path="/rental-information" element={<RentalInformation />} />
                <Route path="/booking/:propertyId" element={<BookingForm />} />
                <Route path="/apartments" element={<Apartments />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<Home />} />
              </Routes>
            </main>
            <Footer />
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
      </SearchProvider>
    </div>
  );
}

export default App;
