import { startOfWeek, addDays, subDays, getWeek, setISOWeek, setYear } from 'date-fns';
import sv from 'date-fns/locale/sv';

export const getSelectedDateFromSelectedWeek = (year, isoWeek) => {
  // Ange året och veckonumret
  let date = setISOWeek(setYear(new Date(), year), isoWeek);

  // Få starten av veckan (måndag enligt ISO-veckonummer)
  let monday = startOfWeek(date, { weekStartsOn: 1 });

  // Subtrahera en dag från måndagen för att få söndagen
  let sunday = subDays(monday, 1);

  return sunday;
}

export const getFutureDateFromFutureWeek = (year, isoWeek, futureIsoWeek) => {
  // Bestäm vilket år som ska användas baserat på de givna veckonumren
  let targetYear = isoWeek <= futureIsoWeek ? year : year + 1;

  // Ange året och det framtida veckonumret
  let date = setISOWeek(setYear(new Date(), targetYear), futureIsoWeek);

  // Få starten av veckan (måndag enligt ISO-veckonummer)
  let monday = startOfWeek(date, { weekStartsOn: 1 });

  // Subtrahera en dag från måndagen för att få söndagen
  let sunday = addDays(monday, 6);

  return sunday;
}

export const getRealWeek = (date = new Date()) => {
  if (!date) {
    return null;
  }
  
  // Hämta veckonummer och året för det givna datumet
  const week = getWeek(date, { locale: sv });

  // Kontrollera om det är december och innevarande år
  if (date.getMonth() === 11) { // December
    const previousWeekDate = new Date(date);
    previousWeekDate.setDate(date.getDate() - 7); // Gå tillbaka en vecka
    const previousWeek = getWeek(previousWeekDate, { locale: sv });

    return previousWeek + 1; // Returnera förra veckans nummer + 1
  }

  // Annars returnera det normala veckonumret
  return week;
};